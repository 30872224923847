import { action, computed, makeObservable, observable, toJS } from 'mobx'
import { superAxiosRequest } from '../axiosApi'

export const exerciseTypes = {
	TEXT: 'ex_text',
	TITLE: 'ex_title',
	LINK: 'ex_link',
	IMAGES: 'ex_image',
	GIF: 'ex_gif',
	ATTENTION: 'ex_attention',
	AUDIO: 'ex_audio',
	VIDEO: 'ex_video',
	VIDEO_LINK: 'ex_video_link',
	ENTER_WORDS: 'ex_write_words',
	CHOOSE_SENTENCES_FORM: 'ex_choose_sentences_form',
	COLORING_BOOK: 'ex_paint_picture',

	CROSSWORD: 'ex_crossword',
	ORDER_SENTENCES: 'ex_arrange_sentences_in_order',
	SELECT_WORDS: 'ex_correct_form',
	WRITE_WORDS: 'ex_words_in_blanks',
	WRITE_PHRASES: 'ex_phrases_in_blanks',
	MAKE_WORD: 'ex_make_word_letters',
	MAKE_WORDS: 'ex_make_words',
	MAKE_SENTENCE: 'ex_make_sentence',
	MAKE_SENTENCES: 'ex_make_sentences',
	MATCH_WORDS: 'ex_word_matching',
	MATCH_WORDS_IMAGES: 'ex_match_description_picture',
	GROUP_WORDS: 'ex_sort_words_in_columns',
	TEST: 'ex_test',
	ESSAY: 'ex_essay',
	PUZZLE: 'ex_puzzle',
}

class EbookChapterStore {
	chapterData = null
	activeSection = 1
	sectionData = null
	errorStatus = ''
	answersData = {}

	constructor() {
		makeObservable(this, {
			chapterData: observable,
			activeSection: observable,
			sectionData: observable,
			answersData: observable,
			errorStatus: observable,

			setChapter: action,
			setActiveSection: action,
			changeActiveSection: action,
			addSection: action,
			setChapterSection: action,
			setSection: action,
			removeSection: action,
			addExercise: action,
			updateExercise: action,
			removeExercise: action,
			setAnswersData: action,
			addAnswer: action,

			chapter: computed,
			section: computed,
			answers: computed,
		})
	}

	addAnswer = (exerciseId, newAnswers) => {
		this.answersData = {
			...this.answersData,
			[exerciseId]: newAnswers,
		}
	}

	fetchChapter = (link, id) => {
		const apiConf = {
			method: 'get',
			link: `${link}/${id}`,
		}
		superAxiosRequest(apiConf)
			.then(res => {
				this.setChapter(res.data)
			})
			.catch(err => {
				if (err.response?.status === 404) {
					this.errorStatus = 404
				}
			})
	}

	fetchSection = id => {
		const apiConf = {
			method: 'get',
			link: 'ebooks/sections/' + id,
		}
		superAxiosRequest(apiConf).then(res => {
			this.setSection(res.data)
		})
	}

	setChapter = data => (this.chapterData = data)

	changeActiveSection = pos => {
		this.activeSection = pos
		if (!!this.chapterData?.sections.length) this.fetchSection(this.chapterData.sections[pos - 1].id)
	}

	setChapterSection = data => {
		this.chapterData = {
			...this.chapterData,
			sections: this.chapterData.sections.map(s => (s.id === data.id ? data : s)),
		}
	}

	setActiveSection = (direction, num) => {
		if (num) this.activeSection = num
		if (direction === 'prev') this.activeSection = Math.max(this.activeSection - 1, 1)
		if (direction === 'next') this.activeSection = Math.min(this.activeSection + 1, this.chapter.sections.length)
	}

	setSection = data => {
		this.sectionData = data
	}

	setAnswersData = data => {
		this.answersData = data
	}

	fetchAnswers = (client_id, exercises) => {
		if (!exercises || exercises.length === 0) {
			console.warn('No exercises to fetch answers for.')
			return
		}

		const exercise_ids = exercises.map(exercise => exercise.id)

		const body = {
			exercise_ids: exercise_ids,
			client_id: client_id,
		}

		superAxiosRequest(
			{
				method: 'post',
				link: `ebooks/answer_student_book`,
			},
			body,
			'json'
		)
			.then(res => {
				this.setAnswersData(res.data)
			})
			.catch(err => {
				if (err.response?.status === 404) {
					this.errorStatus = 404
				}
			})
	}

	addSection = data => {
		this.chapterData = {
			...this.chapterData,
			sections: [...this.chapterData.sections, data],
		}
	}

	removeSection = id => {
		this.chapterData = {
			...this.chapterData,
			sections: this.chapterData.sections.filter(s => s.id !== id),
		}
		this.activeSection = Math.max(this.activeSection - 1, 1)
	}

	addExercise = data => {
		this.sectionData = {
			...this.sectionData,
			exercises: [...this.sectionData.exercises, data],
		}
	}

	updateExercise = data => {
		this.sectionData = {
			...this.sectionData,
			exercises: this.sectionData.exercises.map(ex => (ex.id === data.id ? data : ex)),
		}
	}

	removeExercise = id => {
		this.sectionData = {
			...this.sectionData,
			exercises: this.sectionData.exercises.filter(ex => ex.id !== id),
		}
	}

	get chapter() {
		return toJS(this.chapterData)
	}

	get section() {
		return toJS(this.sectionData)
	}

	get answers() {
		return toJS(this.answersData)
	}
}

EbookChapterStore = new EbookChapterStore()

export default EbookChapterStore
