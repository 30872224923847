import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { superAxiosRequest } from 'axiosApi'
import { localisationMaster } from 'Localisation/Localisation'
import { move } from 'components/Modules/Ebook/functions'
import { checkPermList } from 'helpers'
import { permissions } from 'settings/permissions'
import Stores from 'Stores'
import asideModalStore from 'Stores/asideModalStore'
import EbookChapterStore, { exerciseTypes } from 'Stores/ebookChapterStore'
import cogoToast from 'cogo-toast'
import swal from 'sweetalert'
import ModalExercise from 'components/ContentBox/Global/GlobalModalSingle/templates/Ebooks/ModalExercise/ModalExercise'
import {
	QuestionAttention,
	QuestionImagesList,
	QuestionImagesThumb,
	QuestionSimpleText,
	QuestionUploadedVideo,
} from 'components/ContentBox/Lessons/questions'
import {
	DragWords,
	EnterWords,
	GroupWords,
	ImagesCarousel,
	ImagesMatchText,
	MakeSentence,
	MakeSentences,
	MakeWord,
	MakeWords,
	MatchWords,
	QuestionAudios,
	QuestionTest,
	SelectWords,
	SimpleLink,
	VideoLinks,
	WritingEssay,
} from './types'
import { LessonContext } from '../../BookChapter/LessonContext'
import LessonTeacherStore from 'components/Modules/Ebook/LessonTeacher/lessonTeacherStore'
import LessonStudentStore from 'components/Modules/Ebook/LessonStudent/lessonStudentStore'
import AssignExercise from 'components/ContentBox/Global/GlobalModalSingle/templates/Ebooks/AssignExercise'
import ExerciseHeader from './components/ExerciseHeader'
import { MainExerciseImage } from '../MainExerciseImage/MainExerciseImage'
import SideMenuEbook from '../SideMenuEbook/SideMenuEbook'
import GradeRow from '../GradeRow/GradeRow'
import ExerciseEditPanel from './components/ExerciseEditPanel'
import ExerciseAttemptsPanel from 'components/Modules/Ebook/LessonStudent/ExerciseAttemptsPanel'
import st from './Exercise.module.scss'
import AccentSaveAnswer from '../../LessonStudent/AccentSaveAnswer'
import WriteText from './types/WriteText'
import ExerciseTitle from './components/ExerciseTitle'
import OrderSentences from './types/OrderSentences'
import ChooseSentencesForm from './types/ChooseSentencesForm/ChooseSentencesForm'
import QuestionExample from '../../../../ContentBox/Lessons/questions/QuestionExample/QuestionExample'
import ImagesWriteText from './types/ImagesWriteText'
import ImagesSelectText from './types/ImagesSelectText'
import Crossword from './types/Crossword'
import SolvePuzzle from './types/Puzzle/SolvePuzzle/SolvePuzzle'
import ColoringBook from './types/ColoringBook/ColoringBook'

const questionsWithAnswers = [
	exerciseTypes.ESSAY,
	exerciseTypes.ENTER_WORDS,
	exerciseTypes.CROSSWORD,
	exerciseTypes.ORDER_SENTENCES,
	exerciseTypes.MAKE_SENTENCE,
	exerciseTypes.MAKE_SENTENCES,
	exerciseTypes.MAKE_WORD,
	exerciseTypes.MAKE_WORDS,
	exerciseTypes.CHOOSE_SENTENCES_FORM,
	exerciseTypes.WRITE_WORDS,
	exerciseTypes.WRITE_PHRASES,
	exerciseTypes.SELECT_WORDS,
	exerciseTypes.GROUP_WORDS,
	exerciseTypes.MATCH_WORDS_IMAGES,
	exerciseTypes.MATCH_WORDS,
	exerciseTypes.TEST,
	exerciseTypes.PUZZLE,
	exerciseTypes.COLORING_BOOK,
	exerciseTypes.IMAGES,
]

const ExerciseBook = ({ translate, ex, ...props }) => {
	const refEx = useRef()

	const { setSection, updateExercise, removeExercise, addExercise } = EbookChapterStore
	const { chapter, assignExercisesData, assignExercises, assignExercise, inHomework, addHomework } = LessonTeacherStore
	const { lessonData, lesson, addExAnswers, dropFocus, inFavorites } = LessonStudentStore
	const context = useContext(LessonContext)

	const exerciseAnswers = EbookChapterStore.answers[ex.id] || []

	const isTeacher = checkPermList(permissions.add_init_lesson)
	const isStudent = Stores.isClient

	const section = EbookChapterStore.section || LessonTeacherStore.section

	const isResponseRequired = questionsWithAnswers.includes(ex.type_exercise)
	const [student, setStudent] = useState(null)
	const [answers, setAnswers] = useState([])
	const [mainImage, setMainImage] = useState(null)
	const [sendAnswersButtonActive, setSendAnswersButtonActive] = useState(true)
	const [activeTry, setActiveTry] = useState(1)
	const [attempts, setAttempts] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [aiChecks, setAiChecks] = useState([])
	const [shownAnswers, setShownAnswers] = useState(false)

	// Условия, при которых не отображается главная картинка и текст в некоторых упражнениях
	const dontShowImageAndTextCond = ex.type_exercise !== exerciseTypes.ATTENTION
	const dataForExTemplate = {
		isStudent: isStudent,
		isTeacher: isTeacher,
		is_teacher_view: isTeacher, // дубль
		try: activeTry, // активная попытка
		handleAnswers: setAnswersFromQuestion,
		answers: answers,
		attempts,
		studentAnswer: attempts[activeTry - 1],
	}

	// Боковые меню для различных режимов
	const sideMenuSetting = [
		// Учитель на уроке
		[
			{
				type: 'select_student',
				text: translate?.provide_exercise,
				isPressed: (assignExercises.find(x => x.exercise_id === ex.id)?.students || []).length > 0,
				action: () => teacherAction('show'),
			},
			{ type: 'focus', text: translate?.focus, action: () => teacherAction('focus') },
			...(isResponseRequired && ex.type_exercise !== (exerciseTypes.ESSAY || exerciseTypes.ENTER_WORDS)
				? [
						{
							type: 'checkbox',
							text: translate?.show_answers,
							isPressed: shownAnswers,
							action: () => teacherAction('showRightAnswers'),
						},
				  ]
				: []),
			{
				type: 'home',
				text: translate?.add_to_homework,
				isPressed: inHomework(ex.id),
				action: () => teacherAction('homework'),
			},
		],
		// Учитель на уроке: Выбор ученика
		[{ type: 'users', text: translate?.select_student }],
		// Ученик на уроке
		[
			{
				type: 'save',
				text: translate?.save_answer,
				action: () => sendAnswersButtonActive && saveTry(),
				isDisabled: !sendAnswersButtonActive,
			},
		],
		[
			...(inFavorites(ex.id)
				? [
						{
							type: 'dislike',
							text: 'Dislike',
							isPressed: true,
							action: () => studentAction('remove_from_favorites'),
						},
				  ]
				: [
						{
							type: 'like',
							text: translate?.add_to_favorites,
							action: () => studentAction('add_to_favorites'),
						},
				  ]),
		],
		[
			{
				type: 'delete_homework',
				text: translate?.delete,
				action: props.deleteExerciseFromHomework,
			},
		],
		[
			{
				type: 'check_via_ai',
				text: 'Проверить через AI',
				isDisabled: activeTry > attempts.length || isLoading || typeof dataForExTemplate?.studentAnswer?.result_in_detail === 'string',
				action: () => teacherAction('check_via_ai'),
			},
		],
	]

	// Действия по упражнению
	const action = action => {
		switch (action) {
			case 'edit':
				asideModalStore.activity({
					title: 'Редактирование упражнения',
					modalBodyComponent: () => <ModalExercise />,
					button: 'Сохранить',
					valid: {
						condition_exercise: 'required',
					},
					is_put: true,
					link: 'ebooks/exercise/' + ex.id,
					onSubmitSuccess: updateExercise,
					isResponsive: true,
				})
				break
			case 'copy':
				const copyExercise = { ...ex }
				delete copyExercise.position

				superAxiosRequest({ method: 'post', link: 'ebooks/copy_exercise' }, { exercise_id: ex.id }).then(({ data }) => addExercise(data))
				break
			case 'off':
				break
			case 'delete':
				swal({
					title: translate?.are_you_sure,
					text: `${translate?.delete_exercise}?`,
					icon: 'warning',
					buttons: [translate?.no, translate?.yes],
					dangerMode: true,
				}).then(yes => {
					if (yes) {
						const apiConf = {
							method: 'delete',
							link: 'ebooks/exercise/' + ex.id,
						}
						superAxiosRequest(apiConf).then(() => removeExercise(ex.id))
					}
				})
				break
			case 'up':
			case 'down':
				let arr = section.exercises.map(x => x.id)
				arr = move(arr, arr.indexOf(ex.id), arr.indexOf(ex.id) + (action === 'up' ? -1 : 1))

				const apiConf = {
					method: 'put',
					//link: 'lessons/exercise_position/' + section.id
					link: 'ebooks/exercise_position/' + section.id,
				}
				let body = {
					exercise_position: arr,
				}

				superAxiosRequest(apiConf, body)
					.then(res => {
						props.scrollTo(res.data.exercises.findIndex(x => x.id === ex.id))
						setSection(res.data)
					})
					.catch(() => {
						props.scrollTo(section.exercises.findIndex(x => x.id === ex.id))
					})
				break
			default:
				break
		}
	}

	// Действия преподавателя
	const teacherAction = action => {
		switch (action) {
			case 'show':
				const ms = {
					title: translate?.select_student,
					modalBodyComponent: () => <AssignExercise allStudents={chapter.students} />,
					button: translate?.save,
					link: 'ebooks/assign_exercise',
					onSubmitSuccess: assignExercise,
				}
				const md = {
					uuid: chapter.uuid,
					exercise_id: ex.id,
					students: assignExercises.find(x => x.exercise_id === ex.id)?.students.map(x => x.student_id) || [],
				}
				asideModalStore.activity(ms, md)
				break
			case 'focus':
				const body = {
					type: 'notifications.notify_focus_on_exercise',
					message: { exercise_id: ex.id },
				}
				props.sendMessage(body)

				cogoToast.info(`${translate?.focused_exercise} ${props.number}`, { position: 'bottom-right' })
				break
			case 'homework':
				swal({
					title: `${translate?.add_exercise}?`,
					icon: 'info',
					buttons: [translate?.no, translate?.yes],
				}).then(yes => {
					if (yes) {
						superAxiosRequest(
							//{method: 'post', link: 'ebooks/add_to_hw'},
							{ method: 'post', link: 'ebooks/homework_exercise' },
							{ uuid: chapter.uuid, exersice_id: ex.id }
						)
							.then(res => {
								addHomework(res.data.exercises)
							})
							.catch(() => {})
					}
				})
				break
			case 'showRightAnswers':
				superAxiosRequest({ method: 'get', link: 'ebooks/correct_answers/' + ex.id })
					.then(res => {
						setAttempts([res.data])
						setActiveTry(1)
						setStudent(null)
						setShownAnswers(true)
					})
					.catch(error => {
						// console.log(`Some error occurred: ${error.response.data.error}`)
					})
				break
			case 'check_via_ai':
				setIsLoading(true)
				const apiConf = {
					method: 'post',
					link: 'ebooks/answers_essay_student',
				}
				const payload = {
					condition_exercise: ex.condition_exercise,
					text_exercise: getStudentAnswers(student).answers[activeTry].answer,
					exercise: ex.id,
					ebook: chapter.ebook,
					student_id: getStudentAnswers(student).id,
					attempt: activeTry,
					uuid: chapter.uuid,
				}
				superAxiosRequest(apiConf, payload)
					.then(res => {
						setAiChecks(prevState => [...prevState, res.data])
						setIsLoading(false)
					})
					.catch(error => {
						setIsLoading(false)
						// console.log(`Some error occurred`)
					})
				break
			default:
				break
		}
	}

	// Действия ученика
	const studentAction = action => {
		switch (action) {
			case 'add_to_favorites':
				LessonStudentStore.addToFavorites(ex.id)
				break
			case 'remove_from_favorites':
				LessonStudentStore.removeFromFavorites(ex.id)
				break
			default:
				break
		}
	}

	const viewAnswers = student => {
		setStudent(student)

		const students = assignExercises.find(ae => ae.exercise_id === ex.id)?.students || []
		const answers = students.find(x => x.student_id === student.id)?.answers

		setAttempts(answers || [])

		setActiveTry(1)
	}

	function setAnswersFromQuestion(ans, saveButtonIsActive) {
		//устанавливаем ответы из шаблона задания
		setAnswers(ans)
		//если в задании нет проверки на активность кнопки оставляем ее активной
		if (saveButtonIsActive === undefined) return
		//если проверка есть присваиваем значение из шаблона вопроса
		setSendAnswersButtonActive(saveButtonIsActive) //если проверка есть присваиваем значение из шаблона вопроса
	}

	const saveTry = () => {
		setSendAnswersButtonActive(false)
		let answer
		const apiConf = {
			method: 'post',
			link: 'ebooks/answ_exrs',
		}
		switch (ex.type_exercise) {
			case exerciseTypes.SELECT_WORDS:
			case exerciseTypes.WRITE_PHRASES:
			case exerciseTypes.MAKE_WORD:
			case exerciseTypes.MAKE_SENTENCE:
				answer = answers.join('|')
				break
			case exerciseTypes.MAKE_WORDS:
			case exerciseTypes.MAKE_SENTENCES:
				answer = answers.map(arr => arr.join('|'))
				break
			case exerciseTypes.MATCH_WORDS:
				answer = answers.map(pair => {
					const [word_1, word_2] = pair.split('~~~')
					return { word_1, word_2 }
				})
				break
			case exerciseTypes.MATCH_WORDS_IMAGES:
				answer = answers.map(pair => ({ text: pair.description, image: pair.link }))
				break
			case exerciseTypes.GROUP_WORDS:
				answer = answers.map(ans => ({
					description: ans.description,
					value: ans.words.join('|'),
				}))
				break
			case exerciseTypes.TEST:
				answer = answers.slice(1).map((ans, idx) => ({
					text: ex.data.questions[idx].text,
					variants: ans.map(a => ({ text: a })),
				}))
				break
			case exerciseTypes.PUZZLE:
				answer = answers[0]
				break
			default:
				answer = answers
				break
		}
		const body = {
			exercise_id: ex.id,
			answers: answer,
			painted_picture: mainImage,
		}

		if (ex.type_exercise === exerciseTypes.TEST) {
			body.time = answers[0]
		}

		superAxiosRequest(apiConf, body)
			.then(res => {
				EbookChapterStore.addAnswer(ex.id, res.data)
				setAttempts(res.data)
				if (activeTry < 11) {
					setAnswers([])
					setActiveTry(activeTry + 1)
				}
			})
			.catch(err => {
				if (err.response) alert(err.response?.data.error)
			})
	}

	const renderExTemplate = ({ type_exercise: type, ...exercise }) => {
		let body
		switch (type) {
			case exerciseTypes.IMAGES:
				switch (exercise.show_like) {
					case 'sequence':
						body = (
							<QuestionImagesList
								mainImage={mainImage}
								handleAnswers={dataForExTemplate.handleAnswers}
								images={exercise.items}
								text={exercise.text_exercise}
							/>
						)
						break
					case 'mini':
						body = (
							<QuestionImagesThumb
								mainImage={mainImage}
								handleAnswers={dataForExTemplate.handleAnswers}
								images={exercise.items}
								text={exercise.text_exercise}
							/>
						)
						break
					case 'slider':
						body = (
							<ImagesCarousel
								mainImage={mainImage}
								handleAnswers={dataForExTemplate.handleAnswers}
								images={exercise.items}
								text={exercise.text_exercise}
							/>
						)
						break

					default:
						break
				}
				break
			case exerciseTypes.GIF:
				body = <QuestionImagesList images={exercise.items} text={exercise.text_exercise} />
				break
			case exerciseTypes.AUDIO:
				body = <QuestionAudios items={exercise.items} condition_exercise={exercise.condition_exercise} text={exercise.text_exercise} />
				break
			case exerciseTypes.VIDEO_LINK:
				body = <VideoLinks videos={exercise.data} text={exercise.text_exercise} />
				break
			case exerciseTypes.ESSAY:
				body = <WritingEssay ex={exercise} {...dataForExTemplate} aiChecks={aiChecks} />
				break
			case exerciseTypes.ENTER_WORDS:
				body = <WriteText ex={exercise} {...dataForExTemplate} />
				break
			case exerciseTypes.COLORING_BOOK:
				body = <ColoringBook ex={exercise} {...dataForExTemplate} />
				break
			case exerciseTypes.PUZZLE:
				body = <SolvePuzzle ex={exercise} {...dataForExTemplate} />
				break
			case exerciseTypes.CROSSWORD:
				body = <Crossword ex={exercise} {...dataForExTemplate} />
				break
			case exerciseTypes.MATCH_WORDS_IMAGES:
				switch (exercise.subtype) {
					case 'move':
						body = <ImagesMatchText data={exercise.data} text={exercise.text_exercise} {...dataForExTemplate} />
						break
					case 'write':
						body = <ImagesWriteText data={exercise.data} text={exercise.text_exercise} {...dataForExTemplate} />
						break
					case 'select':
						body = <ImagesSelectText data={exercise.data} text={exercise.text_exercise} {...dataForExTemplate} />
						break
					default:
						break
				}

				break
			case exerciseTypes.MAKE_SENTENCE:
				body = <MakeSentence sentence={exercise.data.sentence} {...dataForExTemplate} {...exercise} />
				break
			case exerciseTypes.MAKE_SENTENCES:
				body = <MakeSentences {...dataForExTemplate} exercise={exercise} />
				break
			case exerciseTypes.GROUP_WORDS:
				body = <GroupWords items={exercise.data} {...dataForExTemplate} />
				break
			case exerciseTypes.WRITE_PHRASES:
				body = <DragWords items={exercise.data} {...dataForExTemplate} />
				break
			case exerciseTypes.SELECT_WORDS:
				body = <SelectWords items={exercise.data} {...dataForExTemplate} mode={context.mode} />
				break
			case exerciseTypes.LINK:
				body = <SimpleLink data={exercise.data || {}} />
				break

			case exerciseTypes.VIDEO:
				body = <QuestionUploadedVideo videos={exercise.items || []} v_link={exercise.items?.[0]?.file || ''} text={exercise.text_exercise} />
				break
			case exerciseTypes.WRITE_WORDS:
				body = <EnterWords items={exercise.data} {...dataForExTemplate} />
				break
			case exerciseTypes.MAKE_WORD:
				body = <MakeWord word={exercise.data} {...dataForExTemplate} {...exercise} />
				break
			case exerciseTypes.MAKE_WORDS:
				body = <MakeWords {...dataForExTemplate} {...exercise} />
				break
			case exerciseTypes.CHOOSE_SENTENCES_FORM:
				body = <ChooseSentencesForm {...dataForExTemplate} {...exercise} />
				break
			case exerciseTypes.MATCH_WORDS:
				body = <MatchWords data={exercise.data} {...dataForExTemplate} {...exercise} />
				break

			case exerciseTypes.ORDER_SENTENCES:
				body = <OrderSentences data={exercise.data} {...dataForExTemplate} {...exercise} />
				break

			//только для преподавателя
			case exerciseTypes.TEST:
				body = (
					<QuestionTest
						text={exercise.text_exercise}
						questions={exercise.data.questions}
						isTimer={exercise.data.is_timer}
						time={exercise.data.time}
						isPoint={exercise.data.is_point}
						saveTry={saveTry}
						{...dataForExTemplate}
					/>
				)
				break
			case exerciseTypes.ATTENTION:
				body = <QuestionAttention images={exercise.items} image={ex.image} text={exercise.text_exercise} />
				break
			default:
				break
		}
		return body
	}

	const getStudentAnswers = st => {
		// Все студенты данного упражнения
		const students = assignExercises.find(ae => ae.exercise_id === ex.id)?.students || []
		// Ответы выбранного студента
		const answers = students.find(x => x.student_id === st.id)?.answers

		let arr = []
		for (let index = 1; index <= 10; index++) {
			arr[index] = {
				attempt: index,
				result: answers?.[index - 1]?.result || null,
			}
		}
		if (answers) {
			answers.forEach(item => (arr[item.attempt] = item))
		}

		return { ...st, answers: arr }
	}

	useEffect(() => {
		if (!props.focused) return
		refEx.current.scrollIntoView({ behavior: 'smooth' })
		dropFocus()
	}, [dropFocus, props.focused])

	useEffect(() => {
		if (answers.length) return
		setSendAnswersButtonActive(false)
	}, [answers])

	useEffect(() => {
		let answers = []
		if (context.mode === 'student' && exerciseAnswers.length > 0) {
			setActiveTry(Math.min(exerciseAnswers.length + 1, 10))
		} else if (context.mode === 'teacher') {
			const students = [...assignExercises].find(ae => ae.exercise_id === ex.id)?.students || []
			if (student) {
				answers = students.find(st => st.student_id === student.id)?.answers || []
			}
		} else if (context.mode === 'teacher-homework') {
			answers = props.answers
		}

		setAttempts(exerciseAnswers)
	}, [assignExercisesData, lessonData, props.answers, exerciseAnswers, context.mode])

	useEffect(() => {
		if (attempts.length <= activeTry) {
			setSendAnswersButtonActive(false)
		}
	}, [activeTry, attempts])

	const onClose = () => {
		setStudent(null)
		setAttempts([])
	}

	const isTitleEx = ex.type_exercise === exerciseTypes.TITLE

	return (
		<div ref={refEx}>
			{/*=== Шапка вопроса для учителя ===*/}
			{context.mode === 'teacher' && student && (
				<GradeRow setClose={onClose} user={getStudentAnswers(student)} activeTry={activeTry} activateTry={setActiveTry} />
			)}

			{/*=== Шапка вопроса для ученика ===*/}
			{context.mode === 'student' && isResponseRequired && (
				<ExerciseAttemptsPanel activeTry={activeTry} setActiveTry={setActiveTry} answers={exerciseAnswers} />
			)}

			{/* EDITP*/}
			{/*=== Шапка вопроса для режима редактирования урока ===*/}
			{/*   {context.mode === 'edit' &&*/}
			{/*    <ExerciseEditPanel action={action} isFirst={props.isFirst} isLast={props.isLast}/>}*/}
			{context.mode === 'edit' && (
				<ExerciseEditPanel action={action} isFirst={props.isFirst} isLast={props.isLast} settings={sideMenuSetting[0]} onlyCheckboxes={true} />
			)}

			{/*Шапка упражнения для учителя в Домашнем задании*/}
			{context.mode === 'teacher-homework' && isResponseRequired && props.student && (
				<ExerciseAttemptsPanel activeTry={activeTry} setActiveTry={setActiveTry} answers={props.answers} />
			)}

			{isTitleEx ? (
				<ExerciseTitle data={{ ...props, ex }} section={section || ex.section} />
			) : (
				<ExerciseHeader data={{ ...props, ex }} section={section || ex.section} />
			)}

			<div className={`${st.contentBox} ${isTitleEx ? st.removePadding : ''}`}>
				<div className={st.content}>
					{ex.image && dontShowImageAndTextCond && (
						<MainExerciseImage image={ex.image} exID={ex.id} setMainImage={setMainImage} {...dataForExTemplate} />
					)}
					{ex.text_exercise && dontShowImageAndTextCond && <QuestionSimpleText text={ex.text_exercise} />}
					{ex.example && <QuestionExample text={ex.example} />}
					{renderExTemplate(ex)}
				</div>
				{context.mode === 'teacher' && (
					<div className={st.sidebar}>
						{!isTitleEx && <SideMenuEbook setting={sideMenuSetting[0]} />}
						{isResponseRequired && (
							<SideMenuEbook
								students={chapter.students.map(getStudentAnswers)}
								student={student}
								setState={viewAnswers}
								setting={ex.type_exercise === exerciseTypes.ESSAY ? [...sideMenuSetting[1], ...sideMenuSetting[5]] : sideMenuSetting[1]}
							/>
						)}
					</div>
				)}
				{context.mode === 'student' && (
					<div className={st.sidebar}>
						{isResponseRequired && <SideMenuEbook setting={sideMenuSetting[2]} />}
						<SideMenuEbook setting={sideMenuSetting[3]} />
					</div>
				)}
				{context.mode === 'teacher-homework' && (
					<div className={st.sidebar}>
						<SideMenuEbook setting={sideMenuSetting[4]} />
					</div>
				)}
				{/* Акцент на блоке сохранения */}
			</div>
			{context.mode === 'student' && isResponseRequired && !dataForExTemplate.studentAnswer && (
				<AccentSaveAnswer isActive={sendAnswersButtonActive} onClick={saveTry} />
			)}
		</div>
	)
}

export default observer(ExerciseBook)
