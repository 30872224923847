import React, { useEffect } from 'react'
import swal from 'sweetalert'
import { superAxiosRequest } from 'axiosApi'
import { observer } from 'mobx-react'
import { move } from '../functions'
import Stores from 'Stores'
import asideModalStore from 'Stores/asideModalStore'
import ebookChapterStore, { exerciseTypes } from 'Stores/ebookChapterStore'
import { LessonContext } from './LessonContext'
import BlueLine from './components/BlueLine'
import Loader from 'components/UI/Loader/Loader'
import ModalExercise from 'components/ContentBox/Global/GlobalModalSingle/templates/Ebooks/ModalExercise/ModalExercise'
import SectionEditMenu from 'components/ContentBox/Lessons/components/SectionEditMenu/SectionEditMenu'
import notFoundEbook from 'components/ContentBox/img/not_found_ex_or_section.svg'
import BookPaper from '../Common/BookPaper/BookPaper'
import PaperHeader from '../Common/PaperHeader/PaperHeader'
import useLocalization from 'hooks/useLocalization'
import PageHeader from 'components/UI/PageHeader/PageHeader'
import st from './BookChapter.module.scss'
import SectionSlider from './components/SectionSlider/SectionSlider'
import { NavLink } from 'react-router-dom'
import { ReactComponent as ArrowRightIcon } from 'components/UI/icons/arrow-right-line.svg'
import { permissions } from 'settings/permissions'
import { checkPermList } from 'helpers'
import { useAIChat } from 'hooks/useAIChat'
import AIChat from 'components/ContentBox/AIChat/AIChat'
import AIButton from 'components/UI/AIButton/AIButton'
import ExerciseBook from '../Common/Exercise/ExerciseBook'

const msSection = {
	type: 'add_section_in_book',
	valid: {
		name: 'required',
		color: 'required',
	},
	link: 'ebooks/sections',
}

const BookChapter = props => {
	const id = +props.match.params.id

	const {
		chapter,
		section,
		activeSection,
		setActiveSection,
		changeActiveSection,
		errorStatus,
		chapterData,
		addSection,
		setChapterSection,
		removeSection,
		addExercise,
	} = ebookChapterStore

	const { isClient } = Stores

	const { toggleChat, isAIChatOpen } = useAIChat(id, 'book')

	const { translate } = useLocalization({
		section: 'ebooks',
		subsection: 'chapter',
		dict: {
			hint: 'This is the interactive lesson preview and editing mode.',
			sections: 'Sections',
			empty_section: 'To add exercises, first need to add sections',
			add_new_section: 'Add New Section',
			create_new_exercise: 'Create a new exercise',
			no_exercises: 'There are no exercises in the lesson yet.',
			no_sections: 'To add a new exercise, first add a section.',
			adding_new_exercise: 'Adding a new exercise',
			add_new_exercise: 'Add New Exercise',
			add: 'Add',
			add_section: 'Add section',
			no: 'No',
			yes: 'Yes',
			edit_section: 'Edit section',
			save: 'Save',
			are_you_sure: 'Are you sure',
			are_you_sure_descr: 'Are you sure you want to delete the partition',
			editing: 'Editing: ',
			no_such_found: 'No such chapter found',
			delete_exercise: 'Are you sure you want to delete the exercise',
		},
	})

	const context = isClient ? { mode: 'student' } : { mode: 'edit' }

	const refs = []

	const msCreateQuestion = {
		title: translate.adding_new_exercise,
		modalBodyComponent: () => <ModalExercise />,
		button: translate.add,
		valid: {
			condition_exercise: 'required',
		},
		link: 'ebooks/exercise',
		onSubmitSuccess: addExercise,
		isResponsive: true,
	}

	const sectionActions = (action, data) => {
		let body, ms
		switch (action) {
			case 'add':
				ms = {
					...msSection,
					title: translate.add_section,
					button: translate.add,
					onSubmitSuccess: addSection,
				}
				asideModalStore.activity(ms, { chapter_id: chapter.id })
				break
			case 'edit':
				ms = {
					...msSection,
					title: translate.edit_section,
					button: translate.save,
					is_put: true,
					link: `${msSection.link}/${data.id}`,
					onSubmitSuccess: setChapterSection,
				}
				const md = {
					name: data.name,
					chapter_id: chapter.id,
					color: data.color?.id,
					image: data.image,
				}
				asideModalStore.activity(ms, md)
				break
			case 'delete':
				swal({
					title: `${translate.are_you_sure}?`,
					text: `${translate.are_you_sure_descr}?`,
					icon: 'warning',
					buttons: [translate.no, translate.yes],
					dangerMode: true,
				}).then(yes => {
					if (yes) {
						const apiConf = {
							method: 'delete',
							link: `${msSection.link}/${data.id}`,
						}
						superAxiosRequest(apiConf).then(() => {
							removeSection(data.id)
						})
					}
				})
				break
			case 'up':
			case 'down':
				let arr = chapter.sections.map(x => x.id)
				arr = move(arr, arr.indexOf(data.id), arr.indexOf(data.id) + (action === 'up' ? -1 : 1))

				const apiConf = {
					method: 'post',
					link: 'ebooks/section_positions/' + chapter.id,
				}
				body = {
					chaptersection_position: arr,
					chapter: chapter.id,
				}

				superAxiosRequest(apiConf, body).then(() => {
					ebookChapterStore.fetchChapter(isClient ? 'ebooks/student_ebook' : 'ebooks/chapter_sections', id)
				})
				break
			default:
				break
		}
	}

	const onChangeSectionHandler = direction => {
		setActiveSection(direction)
		window.scrollTo({ top: 0 })
	}

	const scrollToExercise = idx => {
		if (refs[idx]) {
			const $ex = refs[idx]
			const rect = $ex.getBoundingClientRect()

			window.scrollTo({
				top: window.pageYOffset + rect.y - 61,
				behavior: 'smooth',
			})
		}
	}

	const dataStorage = localStorage.getItem('ud')
	const profile = JSON.parse(dataStorage)
	const client_id = profile.user_data.profile.id

	useEffect(() => {
		const chapterId = Number(id)
		ebookChapterStore.fetchChapter(isClient ? 'ebooks/student_ebook' : 'ebooks/chapter_sections', chapterId)
	}, [isClient, id])

	useEffect(() => {
		if (!isClient) return
		if (!section || !section.exercises || section.exercises.length === 0) return

		ebookChapterStore.fetchAnswers(client_id, section.exercises)
	}, [section, client_id, isClient])

	useEffect(() => {
		changeActiveSection(activeSection)
	}, [activeSection, changeActiveSection, chapterData])

	let num = 0
	const calcNumOfExercise = ex => (ex.type_exercise === exerciseTypes.TITLE ? '' : `${activeSection}.${(num += 1)}`)

	return !!chapter ? (
		<div className={st.container}>
			<PageHeader title={`${!isClient ? translate.editing : ''}${chapter.name}`} />
			<div>
				<div className={st.backButtonContainer}>
					<NavLink to={!isClient ? '/ebooks/book/' + chapter?.ebook : '/ebooks/my-book/' + chapter?.ebook} className={st.backButton}>
						<ArrowRightIcon /> Back to Chapters
					</NavLink>
					{!isClient && <BlueLine>{translate.hint}</BlueLine>}
				</div>
				<div className={st.exercisesSectionsBox}>
					<div>
						<SectionEditMenu
							translate={translate}
							sections={chapter.sections}
							action={!isClient && sectionActions}
							activeSection={activeSection}
							setActiveSection={setActiveSection}
						/>
					</div>
					<BookPaper>
						{chapter.sections.length === 0 && (
							<div className={st.bodyNotFound}>
								<img className={st.imgNotFound} src={notFoundEbook} alt='Section not found' />
								<div className={st.text}>
									<p>{translate.no_exercises}</p>
								</div>
								<button className={st.addNewBtn} onClick={() => sectionActions('add')}>
									{translate.add_new_section}
								</button>
							</div>
						)}

						{section?.exercises.length === 0 && chapter.sections.length > 0 && (
							<div className={st.bodyNotFound}>
								<img className={st.imgNotFound} src={notFoundEbook} alt='Exersices not found' />
								<div className={st.text}>
									<p>{translate.no_exercises}</p>
									<p>{translate.no_sections}</p>
								</div>
								<button className={st.addNewBtn} onClick={() => asideModalStore.activity(msCreateQuestion, { section_id: section.id, data: '' })}>
									{translate.add_new_exercise}
								</button>
							</div>
						)}

						{section?.exercises.length > 0 && chapter.sections.length > 0 && (
							<>
								<PaperHeader chapterName={chapter.name} section={section} />
								<LessonContext.Provider value={context}>
									<div className={st.questionsContainer}>
										{/* EDITP*/}
										{checkPermList(permissions.add_exercise) && (
											<div
												className={st.createQuestion}
												onClick={() =>
													asideModalStore.activity(msCreateQuestion, {
														section_id: section.id,
														data: '',
													})
												}
											>
												<span>{translate.create_new_exercise}</span>
											</div>
										)}

										{section?.exercises.length > 0 && (
											<div className={st.questions}>
												{section?.exercises.map((ex, index) => (
													<div
														key={ex.id}
														ref={div => {
															refs.push(div)
														}}
													>
														<ExerciseBook
															translate={translate}
															number={calcNumOfExercise(ex)}
															ex={ex}
															isFirst={index === 0}
															isLast={index === section?.exercises.length - 1}
															scrollTo={scrollToExercise}
														/>
													</div>
												))}
											</div>
										)}
										{/* EDITP*/}

										{checkPermList(permissions.add_exercise) && (
											<div
												className={st.createQuestion}
												onClick={() =>
													asideModalStore.activity(msCreateQuestion, {
														section_id: section?.id,
														data: '',
													})
												}
											>
												<span>{translate.create_new_exercise}</span>
											</div>
										)}
									</div>
									{chapter.sections.length > 1 && (
										<SectionSlider
											value={activeSection}
											sectionName={section.name}
											total={chapter.sections.length}
											onChange={onChangeSectionHandler}
										/>
									)}
								</LessonContext.Provider>
							</>
						)}
					</BookPaper>
					{/* {isClient &&
						((!isAIChatOpen && (
							<div className={st.aiButton}>
								<AIButton onClick={toggleChat} />
							</div>
						)) ||
							(isAIChatOpen && (
								<div className={st.aiChat}>
									<AIChat toggleChat={toggleChat} />
								</div>
							)))} */}
				</div>
			</div>
		</div>
	) : errorStatus === 404 ? (
		<div className={st.noChapter}>
			<PageHeader title={translate.no_such_found} />
		</div>
	) : (
		<Loader />
	)
}

export default observer(BookChapter)
